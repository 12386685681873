@use 'partials' as *;
@use 'sass:map';

:root {
  @each $name, $code in $colors {
    --color-#{$name}: #{$code};
  }
}

body {
  overflow: scroll;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN',
    'Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体',
    'メイリオ', sans-serif;
  font-weight: normal;
  color: var(--color-black);
  background-color: var(--color-light);
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  backface-visibility: hidden;
}

a,
button {
  cursor: pointer;

  // PC
  @include media-min(pc) {
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}

small {
  font-size: 0.65em;
}

strong {
  font-size: 1.25em;
  font-weight: bold;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: var(--color-pinkishgray);
}

// ライン
hr {
  width: 100%;
  height: size(1);
  margin: 0;
  background-color: var(--color-black);
  border: none;
}

// マーク・強調
mark {
  padding: 0 0.2em;
  color: var(--color-white);
  background-color: var(--color-primary);
}

em {
  font-style: normal;
}

/**
 * Font Awesome icons
 */
/* stylelint-disable-next-line selector-class-pattern */
.svg-inline--fa {
  height: 1em;
}

/*
 * SweetAlert2
 */
/* stylelint-disable-next-line selector-class-pattern */
.swal2-modal .swal2-styled:focus {
  box-shadow: none !important;
}
